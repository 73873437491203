import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import "../styles/theme.scss"
import Layout from "../layouts/layout";
import Card from "../components/card";

const Series = ({ data, location }) => {
    let global = data.contentfulWebsite;
    let cards = data.allContentfulSeries.nodes.map(card => {
        card.url = '/series/' + card.slug;
        return card;
    });

    return (
        <Layout pathname={location.pathname}>
            <Seo metaTitle={global.title} metaDescription={global.description} author={global.author} shareImage={global.shareImage.file.url} />
            <div className="o-wrapper o-container o-content o-content-container o-content-container--large u-text-center">
                <h1>Series</h1>
                {cards.map((card, index) => 
                    <Card size="large" key={index} card={card} cta={global.videoCtaText}></Card>
                )}
            </div>
        </Layout>
    );
};

export default Series

export const pageQuery = graphql`
{
    contentfulWebsite(title: {eq: "UNINTERRUPTED Canada"}) {
        author
        description
        title
        videoCtaText
        shareImage {
            file {
                url
            }
        }
    }
    allContentfulSeries {
        nodes {
            title
            slug
            img {            
                resize(width: 1900) {
                    src
                }
            }
        }
    }
}
`;
