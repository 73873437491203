import React from "react"
import { Link } from "gatsby"

export default class Card extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            hover: false
        };
        this.thumbHover = this.thumbHover.bind(this);
    }

    thumbHover() {
        this.setState({ hover: !this.state.hover });
    }

    render() {
        const image = this.props.card.image? this.props.card.image : this.props.card.img;
        return (
            <div className="c-card o-content-container">
                <img alt="" className={`c-card__thumb ${this.state.hover ? 'c-card__thumb--hover' : ''} ${this.props.size === 'large' ? 'c-card__thumb--large' : ''}`} src={image.resize.src} />
                <div className="h4">{this.props.card.subtitle ? this.props.card.subtitle : this.props.card.series}</div>
                <div className={this.props.size === 'large' ? 'h2' : 'h3 c-card__title'}>{this.props.card.title}</div>
                {this.props.card.description ? <div>{this.props.card.description.description}</div> : '' }
                <div>
                    <Link to={this.props.card.url} className="c-button" onMouseOver={this.thumbHover}  onMouseOut={this.thumbHover}>{this.props.cta}</Link>
                </div>
            </div>
        );
    }
};